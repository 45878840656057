// API
import React, { useEffect, useState, useContext, useRef } from 'react'
import Axios from 'axios'
import { messaging } from '../../../init-fcm'
// Helpers
import Config from '../../../config'

//Context
import { UserContext } from '../../../context/UserContext'
import { UIContext } from '../../../context/UIContext'

// Assets
import { Redirect } from 'wouter'
import queryString from 'query-string'
import { ROLES } from '../../../constants'
import { useQuery } from 'react-query'

// Components
const TeamsDashboardView = React.lazy(() => import('./TeamsDashboardView'))
const LoadingPage = React.lazy(() => import('../../Other/LoadingPage'))
const TeamsCreateContainer = React.lazy(() => import('./TeamsCreateContainer'))

const TeamsDashboard = () => {
    const search = queryString.parse(window.location.search)

    const { user, role } = useContext(UserContext)
    const { page, setPage } = useContext(UIContext)

    const [limit] = useState(11)

    const ref = useRef(null)

    const { data, isLoading } = useQuery([user?._id, limit, page, 'Get Teams'], () =>
        getTeams(user?._id, limit, page)
    )

    useEffect(() => {
        ref.current = true
        const askPermission = async () => {
            if (messaging) {
                try {
                    await messaging.requestPermission()
                    if (window.localStorage.getItem('pushToken') === null) {
                        const token = await messaging.getToken()
                        // console.log(token,' -> token push')
                        try {
                            //setUser({pushId: token})
                            window.localStorage.setItem('pushToken', token)
                        } catch (e) {
                            // eslint-disable-next-line no-console
                            console.error(e, 'no se pudo enviar el token de notificaciones')
                        }
                    }
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log('No hay permisos para enviar notificaciones.', err)
                }

                // register service worker & handle push events
                if ('serviceWorker' in navigator) {
                    window.addEventListener('load', async () => {
                        const registration = await navigator.serviceWorker.register(
                            '/firebase-messaging-sw.js',
                            {
                                updateViaCache: 'none',
                            }
                        )
                        messaging.useServiceWorker(registration)
                        messaging.onMessage((payload) => {
                            const title = payload.notification.title
                            const options = {
                                body: payload.notification.body,
                                icon: payload.notification.icon,
                                actions: [
                                    {
                                        action: payload.fcmOptions.link,
                                        title: 'Book Appointment',
                                    },
                                ],
                            }
                            registration.showNotification(title, options)
                        })
                    })
                }
            }
            return () => (ref.current = false)
        }
        askPermission()
    }, [])

    if (isLoading) return <LoadingPage />

    const { teams, psId, subjectId, teamId, pagination } = data ?? {}

    const maxPage = Math.ceil(pagination.count / pagination.limit)
    if (page > maxPage) setPage(1)

    // If not teams available.
    if (teams?.length === 0) return <TeamsCreateContainer />

    // If its only one team, that team is enabled, if redirect to team is enabled,
    // if its not his first login and if psId and sId are available, then.
    if (role === ROLES.STUDENT) {
        if (
            teams.length === 1 &&
            teams[0].course.isVisible &&
            search?.rtt !== 'false' &&
            user?.isFirstLogin === false &&
            psId &&
            subjectId &&
            teams[0]?._id === teamId
        )
            return <Redirect to={`/team/${psId}/subject/${teamId}/`} />
    }

    // If there are more than one team available, then.
    return (
        <TeamsDashboardView
            {...data}
            data={teams}
            user={user}
            role={role}
            page={page}
            maxPage={maxPage}
            goToPage={setPage}
        />
    )
}

const getTeams = (_id, limit, page) =>
    Axios.get(`${Config.API}/profile/${_id}/teams/`, {
        params: { limit, skip: ((page ?? 1) - 1) * limit },
    }).then(({ data: { professorSchedules, schedule, ...rest } }) => ({
        ...rest,
        teams: professorSchedules.flat(),
        psId: schedule?.actives?.[0]?.professorSchedule?._id ?? professorSchedules?.[0]?._id,
        subjectId: schedule?.actives?.[0]?.subject?._id ?? professorSchedules?.[0]?.subject._id,
        teamId: professorSchedules?.[0]?.team?._id ?? professorSchedules?.[0]?.team._id,
    }))

export default TeamsDashboard
