import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AvatarEgg from '../../components/Styled/AvatarEgg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIContext } from '../../context/UIContext'
import { UserContext } from '../../context/UserContext'
import { AvailableLngs } from '../../i18n'
import Switch from '../../layouts/Switch'

interface IProfileHeadingProps {
    name: string
    lastname: string
    statusMessage?: string
    onBack?: () => unknown
    isEditable: boolean
    handleFileSelect?: (event: React.ChangeEvent<HTMLInputElement>) => unknown
    imageIsLoading: boolean
}

const StudentHeading = (props: IProfileHeadingProps) => {
    const { name, lastname } = {
        ...props,
    }

    const [dropdownShow, setDropdownShow] = useState(false)
    const { titleCaseFn } = useContext(UIContext)
    const { setUser, user } = useContext(UserContext)
    const { t, i18n } = useTranslation(['profile', 'header'])

    const switchLang = async (lang: AvailableLngs) => {
        if ('Intercom' in window) window.Intercom('update', { language_override: lang })
        i18n.changeLanguage(lang)
        setDropdownShow(false)
        if (lang !== user?.language) await setUser({ language: lang })
    }

    useEffect(() => {
        if (i18n.language.includes('es')) switchLang('es')
        if (i18n.language.includes('en')) switchLang('en')
        if (i18n.language.includes('pt')) switchLang('pt')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language])

    return (
        <div className="student-heading-container">
            <div className="navigation">
                {/* <button className="action-link only-desktop" onClick={() => onBack()}>
                    <FontAwesomeIcon className="icon" icon={['fal', 'chevron-left']} />
                    <span>{t('profile:back')}</span>
                </button> */}
                <div className="dropdown show">
                    <button
                        onClick={() => {
                            setDropdownShow(!dropdownShow)
                        }}
                    >
                        <FontAwesomeIcon className="icon" icon={['far', 'globe']} />
                        {t('header:menu.change-language')}
                    </button>
                    {dropdownShow && <Switch setDropdownShow={setDropdownShow} />}
                </div>
            </div>
            <div className="student-data responsive">
                <div className="student-info">
                    <div className="avatar-container">
                        <AvatarEgg
                            isEditable={true}
                            src={user?.urlImage}
                            size={{ mobile: 180, desktop: 210 }}
                        />
                    </div>
                    <div className="rest-info">
                        <h2>
                            {titleCaseFn(name)} {titleCaseFn(lastname)}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentHeading
