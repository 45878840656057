/* eslint-disable no-case-declarations */
/**
 * Documentation: docs/hooks/useActions.md
 *                docs/actions.md
 */

const debug = process.env.NODE_ENV === 'development' ? true : false

// Interfaces
import { Actions, ActionsMap, Payload } from '../interfaces/actions'

import { useNotifications } from './useNotifications'
import { useQueryClient } from 'react-query'
import { useTimeline } from '../pages/Team/Hooks/useTimeline'
// Context
import { useUIContext } from '../context/UIContext'

export const useActions = () => {
    const { notifyToObservers, toastNotification, setRotationInfo } = useUIContext()
    const { newNotification } = useNotifications()
    const { moveToTeams } = useTimeline()
    const queryClient = useQueryClient()

    const ActionsDispatch = async <A extends Actions>(actionType: A, ...payload: ActionsMap[A]) => {
        // eslint-disable-next-line no-console
        debug && console.info(`Dispatching action ${actionType} with payload:`, payload)

        switch (actionType) {
            case 'socket-new-incoming-notification':
                const [notification] = payload as Payload<'socket-new-incoming-notification'>
                newNotification(notification)
                break

            case 'new-rotation':
                const [
                    { courseId: courseID, enableLaunchAlgorithm, title, message, type },
                ] = payload as Payload<'new-rotation'>

                setRotationInfo({
                    inCourse: !enableLaunchAlgorithm,
                    title,
                    message,
                })

                if (!enableLaunchAlgorithm) return

                toastNotification(message, type, 3)
                queryClient.invalidateQueries([courseID], { exact: false })
                queryClient.invalidateQueries(['Team', courseID], { exact: false })

                if (type === 'success') moveToTeams()

                break

            default:
                // Handle unknown action
                // eslint-disable-next-line no-console
                console.info(
                    `Can't dispatch unhandled action "${actionType}". This action will be notified to observers.`,
                    payload
                )
                notifyToObservers(actionType, payload)
                break
        }
    }

    return { ActionsDispatch }
}
