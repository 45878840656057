import firebase from 'firebase/app'
import 'firebase/messaging'

let messaging

// console.log(firebase.messaging.isSupported()) Soportado solo con https://

if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging()
}

export { messaging }
