import { EnrollContainerLogged, EnrollContainerNotLogged } from '../pages/Enroll/EnrollContainer'
import React, { useContext, useMemo } from 'react'
import { User, UserContext } from '../context/UserContext'
import useRoutes, { RouteType } from '../hooks/useRoutes'

import AddStudentContainter from '../pages/Teams/AddStudent/AddStudentContainer'
import { CollabContextProvider } from '../context/CollabContext'
import DataRevisionContainer from '../pages/Students/Import/DataRevisionContainer'
import EmailVerifyContainer from '../pages/Verify/VerifyContainer'
import { FillInfoContextProvider } from '../pages/Signup/Wizard/FillInfoContext'
import LoadingPage from '../pages/Other/LoadingPage'
import LoginContainer from '../pages/Login/LoginContainer'
import NotFoundPage from '../pages/Other/NotFoundPage'
import NotificationContainer from '../pages/NotificationCenter/NotificationContainer'
import { Overview } from '../pages/Team/Overview'
import PasswordManageContainer from '../pages/Password/PasswordManageContainer'
import ProfilePageContainer from '../pages/Profile/ProfilePageContainer'
import { ROLES } from '../constants'
import { Redirect } from 'wouter'
import { Settings } from '../pages/Team/Settings'
import ShareContainer from '../pages/Share/ShareContainer'
import SignupCheckinView from '../pages/Signup/SignupCheckinView'
import SignupContainer from '../pages/Signup/SignupContainer'
import SignupFillInfoContainer from '../pages/Signup/Wizard/SignupFillInfoContainer'
import StudentDetailContainer from '../pages/Students/Detail/StudentDetailContainer'
import StudentImportContainer from '../pages/Students/Import/StudentImportContainer'
import { SubjectContextProvider } from '../context/SubjectContext'
import { SubjectStudentContextProvider } from '../context/SubjectStudentContext'
import { Team } from '../pages/Team/Team'
import TeamCollabContainer from '../pages/Teams/Collab/TeamCollabContainer'
import { TeamContextProvider } from '../pages/Team/Context/TeamContext'
import TeamDetailContainer from '../pages/Teams/Detail/TeamDetailContainer'
import TeamDetailStudentContainer from '../pages/Teams/Detail/TeamDetailStudentContainer'
import TeamManagerContainer from '../pages/Teams/Manage/TeamManagerContainer'
import TeamManagerStudentContainer from '../pages/Teams/Manage/TeamManagerStudentContainer'
import TeamProfileContainer from '../pages/Teams/Profile/TeamProfileContainer'
import TeamSettingsContainer from '../pages/Teams/Settings/TeamSettingsContainer'
import TeamsCreateContainer from '../pages/Teams/Dashboard/TeamsCreateContainer'
import TeamsDashboardContainer from '../pages/Teams/Dashboard/TeamsDashboardContainer'
import TermsAndConditions from '../pages/Signup/TermsAndConditions'
import WizardContainer from '../pages/Wizard/WizardContainer'
import { WizardContextProvider } from '../context/WizardContext'
import Auth0Callback from '../pages/Login/Auth0/Auth0Callback'
import { LoginWithIdToken } from '../pages/Login/LoginWithIdToken'
import { ReportsPDF } from '../pages/Team/Reports/Components/ReportsPDF'

const Reports = React.lazy(() => import('../pages/Team/Reports'))

const AppRouter = () => {
    const { user, role, skipValidation, logged } = useContext(UserContext)

    const routes = useMemo(() => {
        return user ? privateRoutes : publicRoutes
    }, [user])

    const [match] = useRoutes(routes)

    if (user && !skipValidation) {
        if (role === ROLES.STUDENT) {
            if (user.settings?.askForTermsAndConditions && !user.termsAndConditions) {
                return <TermsAndConditions />
            }
        }
        if (user.emailVerified === false) {
            return <EmailVerifyContainer />
        } else if (shouldCompleteProfile(user, role)) {
            return (
                <FillInfoContextProvider>
                    <SignupFillInfoContainer />
                </FillInfoContextProvider>
            )
        }
    }

    return logged ? match || <NotFoundPage /> : match || <LoginContainer />
}

const TeamRouter = () => {
    const routesByRol: { [role in ROLES]: RouteType } = useMemo(
        () => ({
            [ROLES.SUPERADMIN]: {},
            [ROLES.ADMIN]: {
                '/': () => <TeamDetailContainer />,
                '/manager': [
                    () => <TeamManagerContainer />,
                    {
                        '/add': () => <AddStudentContainter />,
                    },
                ],
                '/settings': () => <TeamSettingsContainer />,
                '/profile/:profile': ({ profile }) => <TeamProfileContainer profile={profile} />,
                '/import': [
                    () => <StudentImportContainer />,
                    {
                        '/check': () => <DataRevisionContainer />,
                    },
                ],
                // '/wizard': () => <ShareContainer />,
            },
            [ROLES.TEMPORAL]: {
                '/': () => <TeamDetailContainer />,
                '/manager': () => <TeamManagerContainer />,
                '/settings': () => <TeamSettingsContainer />,
                '/profile/:profile': ({ profile }) => <TeamProfileContainer profile={profile} />,
                '/import': [
                    () => <StudentImportContainer />,
                    {
                        '/check': () => <DataRevisionContainer />,
                    },
                ],
                '/wizard': () => <ShareContainer />,
            },
            [ROLES.COACH]: {
                '/': () => <TeamDetailContainer />,
                '/manager': [
                    () => <TeamManagerContainer />,
                    {
                        '/add': () => <AddStudentContainter />,
                    },
                ],
                '/settings': () => <TeamSettingsContainer />,
                '/import': [
                    () => <StudentImportContainer />,
                    {
                        '/check': () => <DataRevisionContainer />,
                    },
                ],
                '/wizard': () => <ShareContainer />,
                '/profile/:profile': ({ profile }) => <TeamProfileContainer profile={profile} />,
            },
            [ROLES.PROFESSOR]: {
                '/': () => <TeamDetailContainer />,
                '/manager': () => <TeamManagerContainer />,
                '/profile/:profile': ({ profile }) => <TeamProfileContainer profile={profile} />,
            },
            [ROLES.STUDENT]: {
                '/': () => <TeamDetailStudentContainer />,
                '/manager': () => <TeamManagerStudentContainer />,
                '/profile/:profile': ({ profile }) => <TeamProfileContainer profile={profile} />,
                '/collab/:rest*': () => <CollabRouterAndContext />,
                //"/vote": () => <MandatoryVote />,
            },
        }),
        []
    )
    const { role } = useContext(UserContext)
    const [match] = useRoutes(role ? routesByRol[role] : {}, '/team/:ps/subject/:s')
    if (match === undefined || !role) return <LoadingPage />
    return match || <NotFoundPage />
}

const TeamRouterAndContext = ({ ps, s }: { ps: string; s: string }) => {
    const { role } = useContext(UserContext)
    if (role === ROLES.STUDENT) {
        return (
            <SubjectStudentContextProvider professorSchedule={ps} subject={s}>
                <TeamRouter />
            </SubjectStudentContextProvider>
        )
    } else {
        return (
            <SubjectContextProvider professorSchedule={ps} subject={s}>
                <TeamRouter />
            </SubjectContextProvider>
        )
    }
}

const CollabRouter = () => {
    const routesByRol: { [role in ROLES]: RouteType } = useMemo(
        () => ({
            [ROLES.SUPERADMIN]: {},
            [ROLES.ADMIN]: {},
            [ROLES.TEMPORAL]: {},
            [ROLES.COACH]: {},
            [ROLES.PROFESSOR]: {},
            [ROLES.STUDENT]: {
                '/': () => <TeamCollabContainer />,
            },
        }),
        []
    )
    const { role } = useContext(UserContext)
    const [match] = useRoutes(role ? routesByRol[role] : {}, '/team/:ps/subject/:s/collab')
    if (match === undefined || !role) return <LoadingPage />
    return match || <NotFoundPage />
}

const CollabRouterAndContext = () => {
    const { role } = useContext(UserContext)
    if (role === ROLES.STUDENT)
        return (
            <CollabContextProvider>
                <CollabRouter />
            </CollabContextProvider>
        )

    return (
        <CollabContextProvider>
            <CollabRouter />
        </CollabContextProvider>
    )
}

/** All the PrivateRoutes accesible to every user */
const privateRoutes: RouteType = {
    '/': '/dashboard',
    '/dashboard': () => <TeamsDashboardContainer />,
    '/admin/team/:id': ({ id }) => (
        <TeamContextProvider courseID={id}>
            <Team />
        </TeamContextProvider>
    ),
    '/admin/overview/:id': ({ id }) => (
        <TeamContextProvider courseID={id}>
            <Overview />
        </TeamContextProvider>
    ),
    '/admin/settings/:id': ({ id }) => (
        <TeamContextProvider courseID={id}>
            <Settings />
        </TeamContextProvider>
    ),
    '/admin/reports/:id': ({ id }) => (
        <TeamContextProvider courseID={id}>
            <Reports />
        </TeamContextProvider>
    ),
    '/admin/reports/:id/pdf': ({ id }) => (
        <TeamContextProvider courseID={id}>
            <ReportsPDF />
        </TeamContextProvider>
    ),
    '/team/:ps/subject/:s/:rest*': ({ ps, s }) => <TeamRouterAndContext ps={ps} s={s} />,
    '/profile': [
        () => <ProfilePageContainer />,
        {
            '/:profile/from/:professorSchedule/subject/:subject': (props) => (
                <StudentDetailContainer {...props} />
            ),
        },
    ],
    '/login': [
        '/dashboard',
        {
            '/verify': () => <EmailVerifyContainer />,
            '/superadmin': () => <LoginWithIdToken />,
        },
    ],
    '/signup': {
        '/verify': '/dashboard',
        '/complete': () => (
            <FillInfoContextProvider>
                <SignupFillInfoContainer />
            </FillInfoContextProvider>
        ),
        '/privacy': () => <TermsAndConditions />,
    },
    '/welcome': () => <TeamsCreateContainer />,
    '/notifications': () => <NotificationContainer />,
    '/team/new': () => (
        <WizardContextProvider>
            <WizardContainer />
        </WizardContextProvider>
    ),
    '/enroll/:mode/:uuid': [
        ({ mode, uuid }) => <EnrollContainerLogged mode={mode} uuid={uuid} />,
        {
            '/new': () => <div>Nuevo!</div>,
        },
    ],
}

/** All the public route, accesible to guest users */
const publicRoutes: RouteType = {
    '/': '/login',
    '/login': [
        () => <LoginContainer />,
        {
            '/recover': () => <PasswordManageContainer />,
            '/superadmin': () => <LoginWithIdToken />,
        },
    ],
    '/auth/auth0': () => <Auth0Callback />,

    '/signup': [
        () => <SignupContainer mode="professor" />,
        {
            '/checkin': () => <SignupCheckinView />,
            '/verify': () => <NotFoundPage />,
            '/:mode': ({ mode }) => <SignupContainer mode={mode} />,
        },
    ],
    '/enroll/:mode/:uuid': ({ mode, uuid }) => <EnrollContainerNotLogged mode={mode} uuid={uuid} />,
}

/** In case it's a private route, ask for login then redirect to it */
Object.keys(privateRoutes)
    .filter((path) => !publicRoutes[path])
    .forEach((path) => {
        publicRoutes[path] = (_, path) => (
            <Redirect to={path ? `/login?onLogin=${escape(path)}` : '/login'} />
        )
    })

export const requiredKeys: (keyof User)[] = [
    'birthdate',
    'name',
    'lastname',
    'dni',
    'genre',
    'phone',
    'address',
]

// returns false when its ok to redirect to dashboard
const shouldCompleteProfile = (user: User, role: ROLES | undefined) => {
    if (role !== ROLES.STUDENT) {
        return false
    }
    if (user.settings?.mandatoryDataFill) {
        if (!requiredKeys.some((key) => !user[key])) {
            return false
        }
    } else {
        if (user.urlImage || user.settings?.avatarUploadSkipped) {
            return false
        }
    }
    return true
}

export default AppRouter
